import './app.scss';

import domready from 'domready';
import 'lazysizes';
import EventScroller from './lib/event-scroller';
import { loadScript } from './js/helper';

// import libs and components

// GLOBAL OPTIONS
window.OPTIONS = {
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    }
};

/**
 * define function for initialising modules
 */
let siteHeader = null;
let filterBar = null;
let history = null;
let glossary = null;
let backToTop = null;
let eventScroller = '';
let compare = null;
let productDetail = null;
window.youTubeReady = null;

const initContent = ($content) => {
    const pictures = document.querySelectorAll('.lazyload, .lazyloading, .lazyloaded');

    if ($content.querySelector('body')) {
        setTimeout(() => {
            $content.querySelector('body').classList.remove('has--notransition');
            console.log('has--notransition');
        }, 1000);
    }

    languageSwitch();

    if ($content.querySelector('[data-sitekey]')) {
        const $recaptcha = $content.querySelector('[data-sitekey]');

        if ($recaptcha) {
            let scriptIsLoaded = false;

            const interval = setInterval(() => {
                if (typeof grecaptcha !== 'undefined' && $recaptcha.childElementCount > 0) {
                    scriptIsLoaded = true;
                    clearInterval(interval);
                }

                if (typeof grecaptcha !== 'undefined' && $recaptcha.childElementCount === 0) { // eslint-disable-line
                    scriptIsLoaded = true;
                    grecaptcha.render( // eslint-disable-line
                        $recaptcha
                    );
                    clearInterval(interval);
                } else {
                    if (!scriptIsLoaded) {
                        loadScript('//www.google.com/recaptcha/api.js?hl=de_DE');
                    }
                }
            }, 500);
        }
    }

    for (let i = 0; i < pictures.length; i++) {
        const picture = pictures[i];
        const image = picture.querySelector('img');

        if (image !== null) {
            image.classList.add('lazyload');
        }
    }

    // Custom Select Options
    const $choices = $content.querySelectorAll('[data-choices]');
    for (let i = 0; i < $choices.length; i++) {
        import('./components/form/form-choices.js').then(({ FormChoice }) => {
            let search = $choices[i].getAttribute('data-choices-search');
            search = search === 'true';

            let sort = $choices[i].getAttribute('data-choices-sort');
            sort = sort !== 'false';

            const $choice = new FormChoice($choices[i], {
                search: search, // eslint-disable-line
                sort: sort // eslint-disable-line
            });
            $choice.initialize();
        });
    }

    // Init FilterBar
    if ($content.querySelector('[data-filterbar="root"]')) {
        import('./components/overview/filter-bar.js').then(({ FilterBar }) => {
            filterBar = new FilterBar();
        });
    }

    // Init SiteHeader
    if ($content.querySelector('[data-site-header]')) {
        import('./layout/site-header/site-header').then(({ SiteHeader }) => {
            siteHeader = new SiteHeader();
            siteHeader.initialize();
        });
    }

    // Accordion Elements product overview
    const $productAccordionElements = $content.querySelectorAll('[data-accordion="productoverview"]');
    for (let i = 0; i < $productAccordionElements.length; i++) {
        import('./js/accordion.js').then(({ Accordion }) => {
            const $accordion = new Accordion($productAccordionElements[i], {
                speed: 500,
                onlyOneActive: false,
                waitUntilClose: false,
                activeClass: 'is--active',
                breakPointMin: 768
            });
            $accordion.initialize();
        });
    }

    // Accordion Elements inside Footer
    const $accordionElements = $content.querySelectorAll('[data-accordion="footer"]');

    for (let i = 0; i < $accordionElements.length; i++) {
        import('./js/accordion.js').then(({ Accordion }) => {
            const $accordion = new Accordion($accordionElements[i], {
                speed: 500,
                onlyOneActive: true,
                waitUntilClose: false,
                activeClass: 'is--active',
                breakPointMin: 1024
            });
            $accordion.initialize();
        });
    }

    // Social Media
    const $$socialMedia = $content.querySelectorAll('[data-socialmedia="root"]');

    for (let i = 0; i < $$socialMedia.length; i++) {
        import('./modules/social-media/social-media.js').then(({ SocialMedia }) => {
            const $socialMedia = new SocialMedia($$socialMedia[i], {
                speed: 500,
                onlyOneActive: true,
                waitUntilClose: false,
                activeClass: 'is--active',
                breakPointMin: 1024
            });
            $socialMedia.initialize();
        });
    }

    // Accordion Elements Text-Accordion
    const $textAccordionElements = $content.querySelectorAll('[data-accordion="text-accordion"]');
    for (let i = 0; i < $textAccordionElements.length; i++) {
        import('./js/accordion.js').then(({ Accordion }) => {
            const $accordion = new Accordion($textAccordionElements[i], {
                speed: 500,
                onlyOneActive: false,
                waitUntilClose: false,
                activeClass: 'is--active'
            });
            $accordion.initialize();
        });
    }

    // Accordion Elements Glossary
    const $glossaryAccordionElements = $content.querySelectorAll('[data-accordion="glossary"]');
    if ($glossaryAccordionElements.length > 0) {
        for (let i = 0; i < $glossaryAccordionElements.length; i++) {
            import('./js/accordion.js').then(({ Accordion }) => {
                const $accordion = new Accordion($glossaryAccordionElements[i], {
                    speed: 350,
                    onlyOneActive: true,
                    waitUntilClose: true,
                    activeClass: 'is--active'
                });
                $accordion.initialize();
            });
        }
    }

    // Compare Slider
    const $compare = $content.querySelector('[data-compare="root"]');
    if ($compare) {
        import('./pages/compare/compare').then(({ Compare }) => {
            compare = new Compare($compare);
            compare.initialize();
        });
    }

    // Product Detail
    const $productDetail = $content.querySelector('[data-product-detail="root"]');
    if ($productDetail) {
        import('./components/detail/products/product-detail.js').then(({ ProductDetail }) => {
            productDetail = new ProductDetail($productDetail);
            productDetail.initialize();
        });
    }

    // Accessory Detail
    const $accessoryDetail = $content.querySelector('[data-accessorie-detail="root"]');
    if ($accessoryDetail) {
        import('./components/detail/accessories/accessories-detail.js').then(({ AccessoriesDetail }) => {
            const accessoryDetail = new AccessoriesDetail($accessoryDetail);
            accessoryDetail.initialize();
        });
    }

    // Glossary Overview
    const $glossaryOverview = $content.querySelector('[data-glossary="root"]');
    if ($glossaryOverview) {
        import('./pages/glossary/glossary').then(({ Glossary }) => {
            glossary = new Glossary();
            glossary.initialize();
        });
    }

    // Product Overview
    const $productOverview = $content.querySelector('[data-product-overview="root"]');
    if ($productOverview) {
        import('./components/overview/product-overview').then(({ ProductOverview }) => {
            const productOverview = new ProductOverview($productOverview, {
                onSubmitted: ($resultContent) => {
                    initContent($resultContent);
                }
            });
            productOverview.initialize();
        });
    }

    // Application Cases & Reports Overview
    const $appOverview = $content.querySelector('[data-app-overview="root"]');
    if ($appOverview) {
        import('./components/overview/app-overview').then(({ AppOverview }) => {
            const appOverview = new AppOverview($appOverview, {
                onSubmitted: ($resultContent) => {
                    initContent($resultContent);
                }
            });
            appOverview.initialize();
        });
    }

    // Bookmarking Items
    const $bookmarkItems = $content.querySelectorAll('[data-bookmark]:not([data-bookmark-remove])');
    if ($bookmarkItems.length > 0) {
        for (let i = 0; i < $bookmarkItems.length; i++) {
            import('./components/bookmark/bookmark').then(({ Bookmark }) => {
                const bookmark = new Bookmark($bookmarkItems[i], {
                    type: 'item'
                });
                bookmark.setItemEvents();
            });
        }
    }

    // Bookmarking Overlay
    const $bookmarkList = $content.querySelectorAll('[data-bookmark-list]');
    if ($bookmarkList.length > 0) {
        import('./components/bookmark/bookmark').then(({ Bookmark }) => {
            const list = new Bookmark($bookmarkList, {
                type: 'list'
            });
            list.setListEvents();
            list.checkBookmarkStatus($bookmarkList[0]);
        });
    }

    // History
    const $historyRoot = $content.querySelector('[data-history="root"]');
    if ($historyRoot) {
        import('./pages/history/history.js').then(({ History }) => {
            history = new History();
            history.initialize();
        });
    }

    const $contact = $content.querySelector('[data-contact="root"]');
    if ($contact) {
        // general contact js, for example event binding for non map and form elements
        import('./pages/contact/contact').then(({ Contact }) => {
            const contact = new Contact($contact);
            contact.init();
        });

        // Contact Map
        import('./lib/smart-maps-api-loader').then(({ SmartMapsApiLoader }) => {
            const smartMapsApiLoader = new SmartMapsApiLoader($contact);
            smartMapsApiLoader.loadApi().then(initSmartMap);
        });

        const initSmartMap = () => {
            import('./pages/contact/contact-map').then(({ ContactMap }) => {
                const contact = new ContactMap($contact);
                contact.initialize();
            });
        };
    }

    // Contact Formular
    let $contactForm = null;

    if ($content.tagName === undefined) {
        $contactForm = $content.querySelector('[data-contact="main"]');
    } else if ($content.getAttribute('data-contact') === 'main') {
        $contactForm = $content;
    }

    if ($contactForm) {
        import('./pages/contact/contact-form').then(({ ContactForm }) => {
            const contactForm = new ContactForm($contactForm, {
                onSubmitted: ($resultContent) => {
                    initContent($resultContent);
                }
            });
            contactForm.init();
        });
    }

    // let $newsletterForm = null;
    //
    // if ($content.tagName === undefined) {
    //     $newsletterForm = $content.querySelector('[data-newsletter="root"]');
    // } else if ($content.getAttribute('data-newsletter') === 'root') {
    //     $newsletterForm = $content;
    // }
    //
    // if ($newsletterForm) {
    //     import('./pages/newsletter-register/newsletter-register-form').then(({ NewsletterRegisterForm }) => {
    //         const newsletterForm = new NewsletterRegisterForm($newsletterForm, {
    //             onSubmitted: ($resultContent) => {
    //                 initContent($resultContent);
    //             }
    //         });
    //         newsletterForm.init();
    //     });
    // }

    // Tab Navigation
    const $tabNav = $content.querySelector('[data-tab-nav="root"]');
    if ($tabNav) {
        import('./components/detail/products/tab-navigation.js').then(({ TabNavigation }) => {
            const $tabs = new TabNavigation($tabNav, {
                target: document.querySelector('[data-tab-nav="target"]'),
                onSubmitted: ($resultContent) => {
                    initContent($resultContent);
                }
            });
            $tabs.initialize();
        });
    }

    // youtube related calls
    const $youtubeVideos = $content.querySelectorAll('[data-youtube]');

    if ($youtubeVideos.length > 0) {
        import('./lib/youtube-api-loader').then(({ loadYoutubeApi }) => {
            loadYoutubeApi();
        });

        if (window.youTubeReady !== null) {
            import('./modules/youtube-player/youtube-player').then(({ YoutubePlayer }) => {
                const $youtube = new YoutubePlayer($youtubeVideos, window.OPTIONS.breakpoints);
                $youtube.init();
            });
        } else {
            window.onYouTubeIframeAPIReady = () => {
                window.youTubeReady = true;
                import('./modules/youtube-player/youtube-player').then(({ YoutubePlayer }) => {
                    const $youtube = new YoutubePlayer($youtubeVideos, window.OPTIONS.breakpoints);
                    $youtube.init();
                });
            };
        }
    }

    // youtube related calls
    const $youkuVideos = $content.querySelectorAll('[data-youku]');

    if ($youkuVideos.length > 0) {
        for (let i = 0; i < $youkuVideos.length; i++) {
            import('./lib/youku-player.js').then(({ YoukuPlayer }) => {
                const YoukuVideo = new YoukuPlayer($youkuVideos[i]);
                YoukuVideo.initialize();
            });
        }
    }

    // Overlay loader
    const $loadOverlayButton = $content.querySelectorAll('[data-load="overlay"]');
    for (let i = 0; i < $loadOverlayButton.length; i++) {
        import('./js/overlay-load.js').then(({ OverlayLoad }) => {
            const $button = $loadOverlayButton[i];
            const $overlay = new OverlayLoad($button, {
                onSubmitted: ($resultContent) => {
                    initContent($resultContent);
                }
            });
            $overlay.initialize();
        });
    }

    // Teaser-Grid Teaser load with Pagination
    // const $paginations = $content.querySelectorAll('[data-teaser-grid-pagination="root"]');
    // for (let i = 0; i < $paginations.length; i++) {
    //     import('./modules/teaser-grid/teaser-grid.js').then(({ TeaserGrid }) => {
    //         const $pagination = $paginations[i];
    //
    //         const teaserGrid = new TeaserGrid($pagination, {
    //             onSubmitted: ($resultContent) => {
    //                 setTimeout(() => {
    //                     initContent($resultContent);
    //                 }, 1000);
    //             }
    //         });
    //         teaserGrid.initialize();
    //     });
    // }

    // Tile Grid Counter
    const $TileGridCounter = $content.querySelectorAll('[data-tile-grid="root"]');
    if ($TileGridCounter.length > 0) {
        import('./modules/tile-grid/tile-grid.js').then(({ TileGridCounter }) => {
            for (let i = 0; i < $TileGridCounter.length; i++) {
                const $TileCount = new TileGridCounter($TileGridCounter[i]);
                $TileCount.initialize();
            }
        });
    }

    // Tracking
    const $trackingElements = $content.querySelectorAll('[data-tracking-event]');
    if ($trackingElements.length > 0) {
        import('./lib/tracking').then(({ Tracking }) => {
            for (let i = 0; i < $trackingElements.length; i++) {
                const tracking = new Tracking($trackingElements[i]);
                tracking.initialize();
            }
        });
    }

    // PriceList
    const $priceList = $content.querySelector('[data-pricelist="root"]');
    if ($priceList) {
        import('./pages/pricelist/pricelist').then(({ PriceList }) => {
            const priceList = new PriceList($priceList);
            priceList.initialize();
        });
    }

    const $search = $content.querySelector('[data-search="root"]');

    if ($search) {
        import('./components/search/search').then(({ Search }) => {
            const search = new Search($search);
            search.initialize();
        });
    }

    // Back To Top
    const $backToTop = document.querySelector('[data-backtotop="root"]');
    if ($backToTop) {
        import('./components/back-to-top/back-to-top').then(({ BackToTop }) => {
            backToTop = new BackToTop($backToTop);
            backToTop.initialize();
        });
    }

    const $scrollers = document.querySelectorAll('[data-scroller]');
    if ($scrollers.length > 0) {
        eventScroller = new EventScroller({
            onScroll: (position, direction) => {
                if (siteHeader) {
                    siteHeader.checkNavigation(position, direction);
                }

                if (filterBar) {
                    filterBar.checkScrollPosition(position);
                }

                if (history) {
                    history.checkScrollPosition(position, direction);
                }

                if (glossary) {
                    glossary.checkScrollPosition(position, direction);
                }

                if (backToTop) {
                    backToTop.checkPosition(position);
                }

                // if (productDetail) {
                //     productDetail.checkScrollPosition(position);
                // }
            }
        });

        eventScroller.initialize();
    }

    let $resizeTimeout = null;

    window.addEventListener('resize', () => {
        clearTimeout($resizeTimeout);
        document.body.classList.add('has--notransition');
        $resizeTimeout = setTimeout(() => {
            document.body.classList.remove('has--notransition');
            if (siteHeader) {
                siteHeader.checkStatus();
            }

            if (filterBar) {
                filterBar.checkDomPosition();
            }

            if (history) {
                history.setYearOffsets();
            }

            if (glossary) {
                glossary.checkDomPosition();
            }

            if (compare) {
                compare.checkSize(window.innerWidth);
            }

            // if (productDetail) {
            //     productDetail.checkScrollPosition(window.pageYOffset);
            // }
        }, 200);
    });
};

const languageSwitch = () => {
    const $languageSwitch = document.querySelector('[data-language-switch]');

    if ($languageSwitch) {
        $languageSwitch.addEventListener('change', () => {
            window.location = $languageSwitch.value;
        });
    }
};

const browserCheck = () => {
    window.browser = {
        csshover: !window.matchMedia('(pointer:coarse)').matches,
        ios: (/iPhone|iPad|iPod/i).test(navigator.userAgent),
        iphone: (/iPhone|iPod/i).test(navigator.userAgent),
        android: (/(android)/i).test(navigator.userAgent),
        firefox: (/firefox/i).test(navigator.userAgent),
        safari: (/Version\/[\d.]+.*Safari/).test(navigator.userAgent)
    };

    Object.entries(window.browser).forEach(([key, value]) => {
        document.documentElement.classList.add((!value) ? `no-${key}` : `${key}`);
    });
};

/**
 * starting point
 */

domready(function () {
    const initApplication = () => {
        browserCheck();

        initContent(document);

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);

        if (window.location.hash) {
            if (window.history && 'scrollRestoration' in window.history) {
                window.history.scrollRestoration = 'manual';
            }

            setTimeout(() => {
                const id = window.location.hash.slice(1); // Remove the '#'
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 100);
        }
    };

    initApplication();
});
